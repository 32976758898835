$primary: #e95b0f;
$primary-light: #f93;
$success: #38734b;
$info: #385273;
$bright-color: #fff;
$center: center;
$full-width: 100%;
$padding: 1.5rem;
$padding-small: 0.75rem;

@media print {
  .anw-core-message,
  .anw-info-message,
  .anw-no-js-message {
    display: none !important;
  }
}

.anw-core-message {
  background-color: $primary-light;
  color: $bright-color;
  padding: $padding;
  text-align: $center;
  width: $full-width;

  &.anw-core-message-bg-success {
    background-color: $success;
  }
  &.anw-core-message-bg-primary {
    background-color: $primary;
  }
  &.anw-core-message-bg-info {
    background-color: $info;
  }
}

.anw-info-message {
  background-color: $info;
  color: $bright-color;
  padding: $padding-small;
  text-align: $center;
  width: $full-width;
}

.anw-no-js-message {
  background-color: $primary-light;
  padding: $padding;
  text-align: $center;
  width: $full-width;
}
